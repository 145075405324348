(function () {
	'use strict';
	angular
		.module('app')
		.controller('SignOutCtrl', ['$scope', 'daybackIO', SignOutCtrl]);

	function SignOutCtrl($scope, daybackIO) {
		// The only thing we do when we load this controller is immediately sign out
		signOut();

		// Assign signOut function to scope so if there is an issue we can use it
		$scope.signOut = signOut;

		function signOut() {
			daybackIO.signOut();
		}
	}
})();
